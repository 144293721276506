/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import React, { useEffect } from "react";
import styles from "./FeaturedPartsComponent.module.scss";

const FeaturedPartsComponent = ({ items }) => {
  console.log("FeaturedPartsComponent", items);
  useEffect(() => {
    const lazyLoadBgImages = () => {
      const elements = document.querySelectorAll("[data-bg-lazy]");
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const element = entry.target;
              const bgImage = element.getAttribute("data-bg-lazy");
              if (bgImage) {
                element.style.backgroundImage = `url(${bgImage})`;
                element.removeAttribute("data-bg-lazy");
              }
              observerInstance.unobserve(element);
            }
          });
        },
        {
          root: null, // Uses the viewport by default
          threshold: 0.1, // Element is considered visible when 10% is in view
        }
      );

      elements.forEach((el) => observer.observe(el));

      return () => observer.disconnect();
    };

    lazyLoadBgImages();
  }, []);

  return (
    <div className='bg-secondary py-5'>
      <div className='container text-white'>
        <div className={`${styles.gridLayout}`}>
          {items.length > 0 &&
            items.map((item, idx) => {
              const img = item.backImage[0].url;
              const selectionCss =
                item.isGradientAllowed === true ||
                typeof item.isGradientAllowed === "undefined" ||
                item.isGradientAllowed === null
                  ? styles.OverlaySection
                  : styles.OverlaySectionWithoutGradient;
              const doubleHeightCss = item.doubleHeight
                ? `${styles.spanDouble}`
                : `${styles.spanSingle}`;

              return (
                <div
                  className={`${styles.gridItem} ${doubleHeightCss}`}
                  key={idx}
                >
                  <div
                    className={`h-100 ${selectionCss} d-flex flex-column justify-content-end`}
                    data-bg-lazy={img} // Lazy load image URL
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >

                    <h3>{item.title}</h3>
                    <p>{item.subTitle}</p>

                    <Link href={item.url} legacyBehavior>
                      <a className='text-white'>{item.urlButtonCaption}</a>
                    </Link>
                  </div>
                </div>
              );

            })}        </div>
      </div>
    </div>
  );
};

export default FeaturedPartsComponent;
