/* eslint-disable @next/next/no-img-element */
import { useState, useEffect } from "react";
import NextImage from "next/image";
import useWindowSize from "../../utils/useWindowSize";
import styles from "./ImageBlockComponent.module.scss";
const ImageBlockComponent = ({ caption, image, imageMobile }) => {
  const [imageHeight, setImageHeight] = useState(null);
  const [imageMobileHeight, setImageMobileHeight] = useState(null);
  let size = useWindowSize();
  useEffect(() => {
    if (image && image.length > 0) {
      const img = new Image();
      img.src = image[0].url;
      img.onload = () => {
        setImageHeight(img.naturalHeight);
      };
    }

    if (imageMobile && imageMobile.length > 0) {
      const imgMobile = new Image();
      imgMobile.src = imageMobile[0].url;
      imgMobile.onload = () => {
        setImageMobileHeight(imgMobile.naturalHeight);
      };
    }
  }, [image, imageMobile]);

  if (image === undefined) {
    return <></>;
  } else {
    const img = image.length > 0 ? image[0].url : "";
    const imgMobileLocal = imageMobile ? imageMobile[0].url : null;
    const imgCss = `url(${img}); background-size: contain; background-position: center; height: auto;`;
    const imgMobileCss = `url(${imgMobileLocal}); height:100%; color: black`;
    const showCaption = caption ? true : false;

    return (
      <>
        {imgMobileLocal && (
          <>
            <div className='container'>
              {size.width < 768 ? (
                <NextImage
                  src={imgMobileLocal}
                  alt='Image description'
                  width={516}
                  height={imageMobileHeight || 444}
                  sizes='(max-width: 768px) 100vw, 516px'
                  className='w-100'
                  loading='lazy'
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <NextImage
                  src={img}
                  alt='image'
                  width={1296}
                  height={imageHeight || 371} // Use the calculated height
                  className='img-fluid '
                  layout='raw'
                  loading='lazy'
                />
              )}
            </div>
          </>
        )}
        {!imgMobileLocal && (
          <>
            <div
              className={`${styles.bannerSection} w-100`}
              style={{
                backgroundImage: imgCss,
                minHeight: `${imageHeight || 385}px`, // Use the calculated height
              }}
            >
              {showCaption && (
                <div className={styles.polygonWt}>
                  <div className='container p-2'>
                    <div className='pt-4'>
                      <h2 className='h2 text-white fw-500'>{caption}</h2>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
};

export default ImageBlockComponent;
