import React from "react";

const ParaSectionsWithTocComponent = ({ items }) => {
  if (items === undefined) {
    return <></>;
  } else if (items.length > 0) {
    const groupedItems = items.reduce((acc, item, index) => {
      const category = item.category || 'General';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ ...item, originalIndex: index });
      return acc;
    }, {});

    let globalIndex = 0;

    return (
      <>
        <div className='col-12'>
          <h3 id='rg' className='margin-bottom-10'>
            Quick Links
          </h3>
          <div className='pl-4 py-3'>
            <ul className='anchor-menu-links'>
              {Object.keys(groupedItems).map((category, idx) => (
                <div key={idx} className="py-2">
                  <h5 className='category-header' id={"category-" + idx}>{category}</h5>
                  <ul>
                    {groupedItems[category].map((item) => (
                      <li key={item.originalIndex}>
                        <a href={"#item-" + item.originalIndex}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          </div>
        </div>
        {Object.keys(groupedItems).map((category, idx) => (
          <div key={idx}>
          
            {groupedItems[category].map((item) => (
              <div className='col-12' key={item.originalIndex}>
                <div className='component-area'>
                  <h4 className='component-header' id={"item-" + item.originalIndex}>
                    {item.title}
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};

export default ParaSectionsWithTocComponent;
